var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-api-doc-side-bar" },
    [
      _vm._l(
        Object.keys(_vm.apiDocsMetaNormalized),
        function (name, name_index) {
          return _c(
            "b-list-group",
            { key: name_index, attrs: { action: true } },
            [
              _c(
                "b-card",
                { staticClass: "category-container", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "category-header",
                      attrs: { "header-tag": "header", role: "tab" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCollapseFor(name_index)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(name) + "\n        ")]
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: _vm.collapseIdFor(name_index),
                        accordion: "my-accordion",
                        role: "tabpanel",
                      },
                    },
                    _vm._l(
                      Object.keys(_vm.apiDocsMetaNormalized[name]),
                      function (slug, slug_index) {
                        return _c(
                          "b-list-group",
                          {
                            key: slug_index,
                            staticClass: "slug",
                            attrs: { action: true },
                            on: {
                              click: function ($event) {
                                return _vm.updateMarkdown(name, slug)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(slug) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }