<template>
  <div class="public-api-doc-side-bar">
    <template>
      <b-list-group
        v-for="(name, name_index) in Object.keys(apiDocsMetaNormalized)"
        :key="name_index"
        :action="true"
      >
        <b-card no-body class="category-container">
          <b-card-header
            header-tag="header"
            class="category-header"
            role="tab"
            @click="toggleCollapseFor(name_index)"
          >
            {{ name }}
          </b-card-header>
          <b-collapse
            :id="collapseIdFor(name_index)"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-list-group
              v-for="(slug, slug_index) in Object.keys(apiDocsMetaNormalized[name])"
              :key="slug_index"
              class="slug"
              :action="true"
              @click="updateMarkdown(name, slug)"
            >
              <span>
                {{ slug }}
              </span>
            </b-list-group>
          </b-collapse>
        </b-card>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PublicApiDocsSideBar',
  props: {
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('publicApiDocumentation', [
        'apiDocsMetaNormalized',
      ]),
  },
  methods: {
    collapseIdFor(index) {
      return `v-b-toggle.${index}`
    },
    toggleCollapseFor(index) {
      this.$root.$emit('bv::toggle::collapse', this.collapseIdFor(index))
    },
    updateMarkdown(name, slug) {
      const docsId = this.apiDocsMetaNormalized[name][slug]

      this.bus.$emit('navigate-documentation', docsId)
    },
  },
}
</script>

<style scoped lang="scss">

.public-api-doc-side-bar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $ct-ui-color-22;

  .category-container {
    border: none;

    .category-header {
      border-radius: 0;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: $ct-ui-color-14;
      }
    }

    .slug {
      align-items: start;
      vertical-align: middle;
      margin: 0.5em 1em 0 3em;


      &:hover {
        cursor: pointer;
        color: $sl__teal1;
      }

      &:last-child {
        margin-bottom: 2em;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .public-api-doc-side-bar {
    border: none;
  }
}
</style>
